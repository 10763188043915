<template>
  <v-container class="container" fluid>
    <v-card flat class="mb-3">
      <v-btn small class="elevation-4" color="primary" v-on:click="add_click()">
        {{ $t("add_button") }}
      </v-btn>
    </v-card>
    <!-- filter panel -->
    <GsDataFilter
      ref="dataFilter"
      :appSetting="appSettingKey"
      :filters="filters"
      v-on:changed="onFilterChanged"
    />
    <!-- table -->
    <GsDataTable
      ref="table"
      api=""
      endpoint="/license-templates"
      :appSetting="appSettingKey"
      :headers="headers"
      :default-sort-by="['name']"
      :default-sort-desc="[false]"
      :beforeCallApi="beforeCallApi"
      :afterCallApi="afterCallApi"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      item-key="templateId"
      show-expand
      @click:row="rowClicked"
      @after-refresh="afterRefresh"
    >
      <template v-slot:item.renewable="{ item }">
        <span v-if="item.renewable === 0">{{ $t("no") }}</span>
        <span v-if="item.renewable === 1">{{ $t("yes") }}</span>
      </template>

      <template v-slot:item.createdAt="{ item }">
        <span v-if="item.createdAt">{{ item.createdAt | getDisplayDate }}</span>
        <br />
        <span v-if="item.createdBy && item.createdBy.name">
          ({{ item.createdBy.name | empty }})</span
        >
      </template>

      <template v-slot:item.updatedAt="{ item }">
        <span v-if="item.updatedAt">{{ item.updatedAt | getDisplayDate }}</span>
        <br />
        <span v-if="item.updatedBy && item.updatedBy.name">
          ({{ item.updatedBy.name | empty }})</span
        >
      </template>

      <template v-slot:item.actions="{ item }">
        <GsActionsMenu
          :actions="actions"
          :onActivate="actionId => action_activate(actionId, item)"
        />
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <GsLicenseTemplateDetails :value="item" />
        </td>
      </template>
    </GsDataTable>
  </v-container>
</template>

<script>
const { callAPI } = require("ngt-frontend-core").apiOpsBff;
import GsLicenseTemplateDetails from "@/components/GsLicenseTemplateDetails";
import { GsDataTable, GsActionsMenu, GsDataFilter } from "ngt-frontend-core";

export default {
  name: "LicenseTemplates",
  components: {
    GsLicenseTemplateDetails,
    GsDataTable,
    GsActionsMenu,
    GsDataFilter
  },
  data() {
    return {
      appSettingKey: "licenseTemplates",
      items: [],
      expanded: [],
      singleExpand: true,
      filter: {
        name: null
      },
      initialized: false,
      actions: ["edit", "delete"],
      filters: [
        {
          field: "name",
          component: "GsTextFilter",
          params: {}
        }
      ],
      item: null
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("actions"),
          value: "actions",
          width: 100,
          sortable: false
        },
        {
          text: this.$t("name"),
          align: "left",
          value: "name",
          width: 150,
          sortable: true
        },
        {
          text: this.$t("description"),
          align: "left",
          value: "description",
          width: 150,
          sortable: false
        },
        {
          text: this.$t("interval"),
          align: "left",
          value: "interval",
          width: 150,
          sortable: false
        },
        {
          text: this.$t("renewable"),
          align: "left",
          value: "renewable",
          width: 150,
          sortable: false
        },
        {
          text: this.$t("createdAt"),
          value: "createdAt",
          width: 150,
          sortable: true
        },
        {
          text: this.$t("updatedAt"),
          value: "updatedAt",
          width: 150,
          sortable: true
        },
        { text: "", value: "data-table-expand" }
      ];
    }
  },
  mounted() {
    this.initialized = false;
    this.$nextTick(() => {
      this.initialized = true;
    });
  },
  methods: {
    async refreshTable() {
      try {
        // console.log("refreshTable");
        await this.$refs.table.refreshTable();
      } catch (error) {
        this.errorSnackbar(error);
      }
    },
    async beforeCallApi(params) {
      params.url += this.$refs.dataFilter.getFilter();
      params.url += "&r8sFields=createdBy.name,updatedBy.name";
      return params;
    },
    async afterCallApi(params) {
      // await this.calculatePropertiesCustomers(params.items);
      return params;
    },
    afterRefresh({ items }) {
      const expandedId = this.loadExpanded();
      if (expandedId) {
        const expandItem = items.find(p => p.customerId == expandedId);
        if (expandItem) {
          this.expanded = [expandItem];
          this.item = expandItem;
        }
      }
    },
    onFilterChanged() {
      this.$refs.table.goToFirstPage();
    },
    async add_click() {
      this.$router.push_safe(`/license-templates/new`);
    },
    async edit_click(item) {
      this.expand(item);
      this.$router.push_safe(`/license-templates/${item.licenseTemplateId}`);
    },
    async delete_click(item) {
      const confirmResult = await this.$dialog.confirm({
        title: this.$t("confirmation"),
        text: this.$t("delete_modal") + " : " + item.name,
        actions: {
          false: {
            text: this.$t("no"),
            color: "primary"
          },
          true: {
            text: this.$t("yes"),
            color: "default"
          }
        }
      });
      if (!confirmResult) return;

      try {
        const url = `${this.$store.state.idp_api_url}/license-templates/${item.licenseTemplateId}`;
        await callAPI({ url, method: "DELETE" });
        await this.refreshTable();
      } catch (error) {
        this.errorSnackbar(error);
      }
    },
    async action_activate(actionId, item) {
      switch (actionId) {
        case "edit":
          this.edit_click(item);
          break;
        case "delete":
          this.delete_click(item);
          break;
        case "groups":
          this.groups_click(item);
          break;
      }
    },
    rowClicked(item) {
      const earlyReturn = this.expanded.some(
        p => p.customerId === item.customerId
      );
      if (earlyReturn) {
        this.expanded = [];
        this.saveExpanded(null);
        return;
      }
      this.expand(item);
    },
    expand(item) {
      if (!this.$refs.table) return;
      if (item) {
        this.expanded = [item];
        this.saveExpanded(item.customerId);
        this.item = item;
      }
    }
  }
};
</script>

<style lang="scss"></style>
