<template>
  <v-container class="pa-0 ma-0" fluid>
    <div class="d-flex" v-if="value && !loading">
      <div style="width: 25%" tile>
        <!-- <v-card-title>{{ $t("value") }}:</v-card-title> -->
        <v-list-item>
          <v-list-item-content>
            <!-- <v-list-item-title>{{ value.valueName }}</v-list-item-title>
            <v-list-item-subtitle>Id: {{ value.valueId }}</v-list-item-subtitle> -->

            <v-container fluid>
              <v-row>
                <v-col class="details-column-label">
                  <b>{{ $t("id") }}:</b>
                </v-col>
                <v-col>{{ value.templatId }}</v-col>
              </v-row>

              <v-row>
                <v-col class="details-column-label">
                  <b>{{ $t("name") }}:</b>
                </v-col>
                <v-col>{{ value.name }}</v-col>
              </v-row>

              <v-row>
                <v-col class="details-column-label">
                  <b>{{ $t("description") }}:</b>
                </v-col>
                <v-col>{{ value.description }}</v-col>
              </v-row>

              <v-row>
                <v-col class="details-column-label">
                  <b>{{ $t("_uri") }}:</b>
                </v-col>
                <v-col>{{ value._uri }}</v-col>
              </v-row>

              <v-row v-if="value.createdAt">
                <v-col class="details-column-label">
                  <b>{{ $t("createdAt") }}:</b>
                </v-col>
                <v-col>{{ value.createdAt | getDisplayDate }}</v-col>
              </v-row>

              <v-row v-if="value.createdBy && value.createdBy.name">
                <v-col class="details-column-label">
                  <b>{{ $t("createdBy") }}:</b>
                </v-col>
                <v-col>{{ value.createdBy.name | empty }}</v-col>
              </v-row>

              <v-row v-if="value.updatedAt">
                <v-col class="details-column-label">
                  <b>{{ $t("updatedAt") }}:</b>
                </v-col>
                <v-col>{{ value.updatedAt | getDisplayDate }}</v-col>
              </v-row>

              <v-row v-if="value.updatedBy && value.updatedBy.name">
                <v-col class="details-column-label">
                  <b>{{ $t("updatedBy") }}:</b>
                </v-col>
                <v-col>{{ value.updatedBy.name | empty }}</v-col>
              </v-row>
            </v-container>

            <!-- {{ value }} -->
          </v-list-item-content>
        </v-list-item>
      </div>

      <div style="width: 25%" tile>
        <v-list-item>
          <v-list-item-content>
            <v-container fluid>
              <v-row>
                <v-card-title>Clients:</v-card-title>
                <v-list-item
                  two-line
                  v-for="item in clients"
                  :key="item.clientId"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.client_name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <span class="caption font-weight-bold">Code: </span>
                      <span class="caption">{{ item.appCode }}</span>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      <span class="caption font-weight-bold">Type: </span>
                      <span class="caption">{{ item.client_type }}</span>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      <span class="caption font-weight-bold"
                        >{{ $t("_uri") }}:
                      </span>
                      <span class="caption text-wrap">{{ item._uri }}</span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-row>
            </v-container>
          </v-list-item-content>
        </v-list-item>
      </div>

      <div style="width: 25%" tile>
        <v-list-item>
          <v-list-item-content>
            <v-container fluid>
              <v-row>
                <v-card-title>Scopes:</v-card-title>
                <v-list-item v-for="item in scopes" :key="item.scopeId">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.scopeName }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-wrap">
                      <span>{{ item._uri }}</span>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle
                      >{{ $t("createdAt") }}:
                      {{ item.createdAt | getDisplayDate }}
                      ({{ item.createdBy.name | empty }})
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-if="item.updatedAt">
                      {{ $t("updatedAt") }}:
                      {{ item.updatedAt | getDisplayDate }}
                      ({{ item.updatedBy.name | empty }})
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-row>
            </v-container>
          </v-list-item-content>
        </v-list-item>
      </div>

      <div style="width: 25%" tile>
        <v-list-item>
          <v-list-item-content>
            <v-container fluid>
              <v-row>
                <v-card-title>Roles:</v-card-title>
                <v-list-item
                  v-for="item in roles"
                  :key="item.permissionTemplateId"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-wrap">
                      <span>{{ item._uri }}</span>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle
                      >{{ $t("createdAt") }}:
                      {{ item.createdAt | getDisplayDate }}
                      ({{ item.createdBy.name | empty }})
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-if="item.updatedAt">
                      {{ $t("updatedAt") }}:
                      {{ item.updatedAt | getDisplayDate }}
                      ({{ item.updatedBy.name | empty }})
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-row>
            </v-container>
          </v-list-item-content>
        </v-list-item>
      </div>
    </div>
    <div v-if="!value && !loading">
      <span>{{ $t("no_data") }}</span>
    </div>
    <div class="text-center" v-if="loading">
      <v-progress-circular
        indeterminate
        color="primary"
        class="ma-4"
      ></v-progress-circular>
      <span>{{ $t("loading") }}</span>
    </div>
  </v-container>
</template>

<script>
const { callAPI, callBffAPI } = require("ngt-frontend-core").apiOpsBff;

export default {
  name: "GsLicenseTemplateDetails",
  components: {},
  props: {
    value: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      loading: false,
      scopes: [],
      clients: [],
      roles: []
    };
  },
  created: async function() {
    this.getClients(this.value);
    this.getScopes(this.value);
    this.getRoles(this.value);
  },
  watch: {
    value(value) {
      this.getClients(value);
      this.getScopes(value);
      this.getRoles(value);
    }
  },
  methods: {
    async getClients(scope) {
      this.loading = true;
      this.clients = [];
      if (!scope?.client?.length) {
        this.loading = false;
        return;
      }
      let clients = await this.getClient(scope.client);
      clients = clients.filter(p => p != null);
      this.clients = clients;
      this.loading = false;
    },
    async getScopes(client) {
      this.scopes = [];
      this.loading = true;
      if (!client?.scope?.length) {
        this.loading = false;
        return;
      }
      const items = await this.getScope(
        client.scope,
        "&r8sFields=createdBy.name,updatedBy.name"
      );
      this.scopes = items;
      this.loading = false;
    },
    async getRoles(client) {
      this.roles = [];
      this.loading = true;
      if (!client?.role?.length) {
        this.loading = false;
        return;
      }
      const items = await this.getRole(
        client.role,
        "&r8sFields=createdBy.name,updatedBy.name"
      );
      this.roles = items;
      this.loading = false;
    }
  }
};
</script>

<style scoped>
.details-column-label {
  width: 200px;
}
</style>
